<template>
  <jt-navbar :redirect="url" divisionflag="1"></jt-navbar>
  <router-view v-if="hasInitialized"/>
  <jt-footer></jt-footer>
</template>

<script>
import store from './store/index';
import cookie from 'js-cookie';

export default {
  name: 'App',
  data() {
    return {
      userState: store.state,
      router: this.$router,
      hasInitialized: false,
      url: process.env.VUE_APP_PGM_UI, 
      redirectPGMV2Url: process.env.VUE_APP_REDIRECT_URL
    }
  },
  computed: {
    companyId() {
      if (!this.userState.navBarData.company.companyId) return;

      return this.userState.navBarData.company.companyId;
    }
  },
  watch: {
    userState: {
      async handler(newVal, oldVal) {
        if (!newVal) return;
        if (!newVal.navBarData) return;

        this.handleCompanyRedirection();

        if (!this.hasPermission()) return this.router.replace({ path: '/sell-info' });

        this.hasInitialized = true;
      },
      deep: true
    }
  },
  beforeCreate() {
    const jtName = 'jtToken';
    const urlParams = new URLSearchParams(window.location.search);
    const cookieJtToken = cookie.get(jtName);
    let navPoll = null;
    let currentDivisionId = 0;

    if (cookieJtToken) return navBarEventHandler();
    if (!urlParams.has(jtName)) return;

    const jtToken = urlParams.get(jtName);

    if (window.location.hostname.includes('.jobtarget.com')) {
      cookie.set(jtName, jtToken, {
        expires: 1,
        domain: '.jobtarget.com'
      });
    } else {
      cookie.set(jtName, jtToken, { expires: 1 });
    }

    this.handleCompanyRedirection();

    return navBarEventHandler();

    function navBarEventHandler() {
      window.addEventListener('userinfo:jt:navbar', handleNavData);
      window.addEventListener('divisionchange:jt:navbar', handleNavData);

      navPoll = setInterval(pingNav, 250);
    }

    function pingNav() {
      window.dispatchEvent(new CustomEvent('requestuserinfo:jt:navbar'));
    }

    function handleNavData(event) {
      if (event.detail.currentDivision.id === 0) {
        return;
      } else {
        clearInterval(navPoll);
      }

      if (currentDivisionId !== event.detail.currentDivision.id) {
        currentDivisionId = event.detail.currentDivision.id;
        store.methods.setNavBarData(store.state, event.detail);
      }
    }
  },
  methods: {
    hasPermission() {
      if (!this.userState.navBarData.appPermissions) return;

      return this.userState.navBarData.appPermissions.some(data => data.app_id === 2);
    },
    handleCompanyRedirection() {
      const companyIds = [50622, 71512, 60941, 78667, 64941, 
      41068, 109010, 112787, 37544, 103556, 75019, 49796, 11214,
      89385, 83493, 59032, 71177, 19371, 111073, 44902, 57333,
      42600, 39958, 63285, 57631, 37346, 67383, 1211, 98958, 42090,
      76596, 42857, 13135, 39058, 49425, 38678, 94491, 55998, 8378,
      31370, 52279, 11800, 50618, 8299, 68594, 95431, 38428,
      45941, 5139, 88276];

      if (!companyIds.includes(this.companyId)) return;

      const token = this.getToken();
      const redirectUrl = this.buildRedirectUrl(token);
      window.location.href = redirectUrl;
    },

    getToken() {
      const urlParams = new URLSearchParams(window.location.search);
      return cookie.get('token') || urlParams.get('token');
    },

    buildRedirectUrl(token) {
      if (!token) return this.redirectPGMV2Url;

      const url = new URL(this.redirectPGMV2Url);
      url.searchParams.set('token', token);
      return url.toString();
    }
  }
}
</script>

<style>
.filter-section {
  background-color: #F8F9FB;
  margin-left: 0;
  margin-right: 0;
  padding-top: 24px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 24px;
}

.status-label { 
  font-size: 12px;
  font-weight: 500;
  padding: 1px 8px;
  line-height: 18px;
  border-radius: 4px;
}

.active-status {
  background: #C9F7F5;
  color: #11807A;
}

.inactive-status {
  background: #E9ECEF;
  color: #6C757D;
}

.in-progress-status {
  background: #FFF4DE;
  color: #855700;
}

.paused-status {
  background: #FFF4DE;
  color: #855700;
}

.limit-status {
  background: #FFE2E5;
  color: #802932;
}

.filter-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #343A40;
}

.dropdown-item .text {
  width: 100%;
}
</style>
