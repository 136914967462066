<template>
  <div id="jt-programmatic" class="container mt-4 mb-4">
    <div class="row">
      <div class="col-12">
        <div id="jt-programmatic-card" class="card border-0 w-100">
          <Header
            :renderUpdateBudget="renderUpdateBudget"
            :pageloadData="pageloadData"
            :cycle="cycle"
            :currentCycle="currentCycle"
            :campaignContacts="campaignContacts"
            :headerType="0"
            :campaignId="campaignId"
            @update-budget-success="displayUpdateBudgetSuccessToaster"
          />
          <!-- BEGIN: Content -->
          <div v-if="renderData" id="jt-programmatic-body" class="card-body">
            <div class="row filter-section mb-4">
              <div v-if="isMultipleCampaigns" class="col-4">
                <SelectCampaign
                  :campaigns="pageloadData.campaigns"
                  :campaignId="campaignId"
                  @change-campaign="changeSelectedCampaign"
                />
              </div>
              <div class="col-4">
                <SelectCycle
                  :cycles="cycles"
                  :cycleId="cycleId"
                  @change-cycle="changeSelectedCycle"
                />
              </div>
              <div class="col-4">
                <DateRangePicker
                  :dateRangeType="0"
                  @select-date-range="selectDateRange"
                  @date-range-exceed-warning="handleDateRangeExceedWarning"
                />
              </div>
            </div>
            <DateRangePickerWarning v-if="showWarning" />
            <div v-if="displayBudgetWarning" class="row mb-4">
              <BudgetAlert />
            </div>
            <div v-if="updatedBudgetData && updatedBudgetData.success" class="row mb-4">
              <UpdateBudgetSuccess :updatedBudgetData="updatedBudgetData" />
            </div>
            <div v-if="displayCreditWarning" class="row mb-4">
              <CreditAlert
                :creditsAvailable="campaignInventory.creditsAvailable"
                :relationshipManager="campaignContacts.relationship_manager"
                :pageload="pageloadData"
              />
            </div>
            <div v-if="pageloadData.has_inventory && !isCampaignDateRange" class="row mb-4">
              <InventoryInfo :inventory="campaignInventory" />
            </div>
            <div class="row mb-4">
              <div class="col-12">
                <AnalyticsCards
                  :analyticsData="analyticsTopData"
                  :clickableTileReqs="clickableTileReqs"
                />
              </div>
              <div class="col-12">
                <AnalyticsCards
                  :analyticsData="analyticsBtmData"
                  :clickableTileReqs="clickableTileReqs"
                />
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12">
                <DailyTrafficChart
                  :url="campaignChartUrl"
                  :campaignRatio="campaignRatio"
                  :totalApps="totalApps"
                  :chartType="0"
                  @update-sparkline="displaySparklineGraph"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <JobsTable
                  :cycleId="cycleId"
                  :url="campaignJobsUrl"
                  :unpostedUrl="campaignUnpostedJobsUrl"
                  :dateRangeUrl="dateRangeJobsUrl"
                  :cycle="cycle"
                  :campaignRatio="campaignRatio"
                  :isCompanyEAEnabled="isCompanyEAEnabled"
                  :isCampaignDateRange="isCampaignDateRange"
                  :dataTablesType="0"
                  @jobs-analytics-data="displayAnalyticsJobs"
                  @date-range-analytics-data="displayDateRangeAnalytics"
                />
              </div>
            </div>
          </div>
          <!-- END: Content -->
          <div v-else id="jt-programmatic-body" class="card-body">
            <div class="d-flex justify-content-center align-items-center">
              <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import BudgetAlert from '../../components/BudgetAlert.vue';
import CreditAlert from '../../components/CreditAlert.vue';
import SelectCampaign from '../../components/SelectCampaign.vue';
import SelectCycle from '../../components/SelectCycle.vue';
import DateRangePicker from '../../components/DateRangePicker.vue';
import DateRangePickerWarning from '../../components/DateRangePickerWarning.vue';
import AnalyticsCards from '../../components/AnalyticsCards.vue';
import DailyTrafficChart from '../../components/DailyTrafficChart.vue';
import JobsTable from '../../components/JobsTable.vue';
import InventoryInfo from '../../components/InventoryInfo.vue';
import UpdateBudgetSuccess from '../../components/UpdateBudgetSuccess.vue';
import store from '../../store/index';
import httpRequest from '../../mixins/http-request';

export default {
  name: 'CampaignDashboard',
  components: {
    Header,
    SelectCampaign,
    SelectCycle,
    AnalyticsCards,
    DateRangePicker,
    DateRangePickerWarning,
    DailyTrafficChart,
    JobsTable,
    BudgetAlert,
    CreditAlert,
    InventoryInfo,
    UpdateBudgetSuccess
  },
  mixins: [httpRequest],
  data() {
    return {
      userState: store.state,
      router: this.$router,
      renderData: false,
      renderUpdateBudget: false,
      // renderBudgetAllocation: false, Budget Allocation Removal
      isCampaignDateRange: false,
      pageloadData: null,
      campaignId: null,
      cycleId: null,
      cycles: null,
      cycle: null,
      currentCycle: null,
      startDate: null,
      endDate: null,
      showWarning: false,
      campaignChartUrl: null,
      campaignJobsUrl: null,
      campaignUnpostedJobsUrl: null,
      dateRangeJobsUrl: null,
      campaignRatio: null,
      campaignInventory: null,
      campaignContacts: null,
      updatedBudgetData: null,
      totalApps: 0,
      clickableTileReqs: {},
      analyticsTopData: [
        {
          name: 'Jobs Advertised',
          value: '—',
          className: 'col analytics-card card-top-left-border card-top-left-radius',
          isClickableTile: false,
        },
        {
          name: 'Budget',
          value: '—',
          className: 'col analytics-card card-top-left-border budget-tile',
          isClickableTile: false,
        },
        {
          name: 'Current Spend',
          value: '—',
          className: 'col analytics-card card-top-left-border',
          isClickableTile: false,
        },
        {
          name: 'Remaining Budget',
          value: '—',
          className: 'col analytics-card card-top-left-border',
          isClickableTile: false,
        },
        {
          name: 'Average Daily Spend',
          value: '—',
          className: 'col analytics-card card-no-bottom-border card-top-right-radius',
          isClickableTile: false,
        }
      ],
      analyticsBtmData: [
        {
          name: 'Clicks',
          value: '—',
          className: 'col analytics-card card-no-right-border card-bottom-left-radius',
          isClickableTile: false,
        },
        // {
        //   name: 'CPC',
        //   value: '—',
        //   className: 'col analytics-card card-no-right-border',
        //   isClickableTile: false,
        // },
        {
          name: 'Apps',
          value: '—',
          className: 'col analytics-card card-no-right-border apps-tile-all',
          isClickableTile: false,
        },
        {
          name: 'CPA',
          value: '—',
          className: 'col analytics-card card-no-right-border',
          isClickableTile: false,
        },
        {
          name: 'Conversion Rate',
          value: '—',
          className: 'col analytics-card card-all-border card-bottom-right-radius',
          isClickableTile: false,
        }
      ],
    };
  },
  computed: {
    hasDateRange() {
      const { startDate,  endDate } = this;

      return { startDate, endDate };
    },
    isMultipleCampaigns() {
      return !this.pageloadData ? false : this.pageloadData.campaigns.length > 1;
    },
    displayBudgetWarning() {
      if (!this.cycle || this.updatedBudgetData) return false;

      return this.cycle.customer_spend_limit && 
        this.cycle.status_id !== 3 && 
        this.cycle.days_left > 1 &&
        ((this.cycle.available_spend_spent / this.cycle.ratio) / this.cycle.customer_budget) > 0.8;
    },
    displayCreditWarning() {
      return this.campaignInventory.inventoryType === 'Credit' && 
        this.campaignInventory.creditsAvailable < 2 && 
        this.campaignContacts;
    }
  },
  watch: {
    cycleId: {
      async handler(newVal, oldVal) {
        if (!newVal) return;

        this.cycle = this.cycles.find(data => data.campaign_cycle_id === this.cycleId);
      },
      immediate: true,
      deep: true,
    },
    cycle: {
      handler(newVal, oldVal) {
        if (!newVal) return;

        this.campaignRatio = this.cycle.ratio;

        if (
          this.pageloadData.inventory_type === 'Subscription' &&
          !this.isCampaignDateRange && 
          (this.cycle.status_id === 2 || this.cycle.status_id === 4) &&
          this.cycle.customer_spend_limit
        ) {
          this.renderUpdateBudget = true;
        }
        this.loadCycleData();
      },
      immediate: true,
      deep: true,
    },
    cycles: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.currentCycle = this.cycles[0];
        this.getInventoryData();
        
        if (this.updatedBudgetData) {
          this.cycleId = this.currentCycle.campaign_cycle_id;
          this.cycle = this.currentCycle;
          this.loadCycleData();
        }
      },
      immediate: true,
      deep: true,
    },
    hasDateRange() {
      if (!this.startDate && !this.endDate) return;

      this.loadDateRangeData();
    },
    userState: {
      async handler(newVal, oldVal) {
        if (!newVal) return;

        this.resetCampaignData();
        await this.pageload();

        if (this.pageloadData.redirect_singles) return this.router.replace({ path: '/singles' })
        if (this.pageloadData.redirect_select_jobs) return this.router.replace({ path: '/select-jobs'})
        if (this.pageloadData.redirect_scheduled) return this.router.replace({ path: '/sell-info' })

        // this.renderBudgetAllocation = this.pageloadData.has_budget_allocation; Budget Allocation Removal
        this.cycles = this.pageloadData.cycles;
        this.campaignId = this.pageloadData.campaign_id;
        this.cycleId = this.pageloadData.cycle_id;
        this.isCompanyEAEnabled = this.pageloadData.company_ea_enabled;

        await this.getCampaignContacts();

        // redirect to error page if page load is null
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    loadCycleData() {
      if (!this.cycleId || !this.cycles || !this.cycle) return;

      this.getAnalyticsData();
      this.generateCampaignChartUrl();
      this.generateCampaignJobsUrl();
      this.makeTileClickable();

      this.renderData = true;
    },
    loadDateRangeData() {
      if (!this.startDate || !this.endDate) return;

      this.generateDateRangeJobsUrl();
      this.generateDateRangeChartUrl();

      this.renderData = true;
    },
    handleDateRangeExceedWarning() {
      this.showWarning = true;
    },
    async pageload() {
      const userData = this.userState.navBarData;
      const url = `${process.env.VUE_APP_PGM_API}/api/pageload/campaign?recruiter_id=${userData.recruiterId}&division_id=${userData.currentDivision.id}&fname=${userData.firstName}&lname=${userData.lastName}&email=${userData.email}&company_id=${userData.company.companyId}`;

      this.pageloadData = await this.httpGet(url);
    },
    async getInventoryData() {
      const record = {};
      record.inventoryType = this.pageloadData.inventory_type;

      if (record.inventoryType === 'Subscription') {
        record.currentCycleBudget = numeral(this.currentCycle.customer_budget).format('$0,0.00');
        record.monthlyBudget = numeral(this.currentCycle.campaign_budget).format('$0,0.00');
      } else if (record.inventoryType === 'Credit') {
        record.expireDate = this.pageloadData.credit_inventory.expire_date;
        record.creditsAvailable = this.pageloadData.credit_inventory.credits_available;
        record.creditsTotal = this.pageloadData.credit_inventory.credits_total;
      }

      this.campaignInventory = record;
    },
    async getCampaignContacts() {
      const url = `${process.env.VUE_APP_PGM_API}/api/campaign/getcontacts/${this.pageloadData.campaign_id}`;
      this.campaignContacts = await this.httpGet(url);
    },
    async getCampaignCycles(selectedCycleId) {
      const cycleId = selectedCycleId ? selectedCycleId : -1;
      const url = `${process.env.VUE_APP_PGM_API}/api/campaign/getcampaigncycles?campaign_id=${this.campaignId}&cycle_id=${cycleId}`;
      this.cycles = await this.httpGet(url);
    },
    getAnalyticsData() {
      const budget = this.cycle.customer_budget;
      const availableSpend = this.cycle.available_spend;
      const availableSpendSpent = this.cycle.available_spend_spent;
      const ratio = this.cycle.ratio;
      const clicks = this.cycle.clicks;
      const apps = this.cycle.apps;

      this.totalApps = this.cycle.apps;

      this.analyticsTopData[1].value = numeral(budget).format('$0,0.00');

      if (availableSpendSpent >= budget * ratio) {
        this.analyticsTopData[2].value = 'Budget Reached';
      } else {
        this.analyticsTopData[2].value = numeral(availableSpendSpent / ratio).format('$0,0.00');
      }

      if (this.cycle.status_id === 3) {
        this.analyticsTopData[2].name = 'Spend*';
      }

      if (this.cycle.status_id !== 3 && availableSpendSpent < availableSpend) {
        this.analyticsTopData[3].value = numeral(budget - (availableSpendSpent / ratio)).format('$0,0.00');
      }

      this.analyticsTopData[4].value = numeral((availableSpendSpent / ratio) / this.cycle.days_in).format('$0,0.00');

      this.analyticsBtmData[0].value = numeral(clicks).format('0,0');
      this.analyticsBtmData[1].value = numeral(apps).format('0,0');

      if (clicks > 0) {
        //this.analyticsBtmData[1].value = numeral((availableSpendSpent / ratio) / clicks).format('$0,0.00');
        this.analyticsBtmData[3].value = numeral(apps / clicks).format('0,0.00%');
      }

      if (apps > 0) {
        this.analyticsBtmData[2].value = numeral((availableSpendSpent / ratio) / apps).format('$0,0.00');
      }
    },
    generateCampaignJobsUrl() {
      this.campaignJobsUrl = `${process.env.VUE_APP_PGM_API}/api/campaign/getjobsincycle?campaign_id=${this.campaignId}&cycle_id=${this.cycleId}&limit=${process.env.VUE_APP_PGM_REQUEST_LIMIT}`;
      // this.campaignUnpostedJobsUrl = `${process.env.VUE_APP_PGM_API}/api/campaign/getunpostedjobs?campaign_id=${this.campaignId}&type=1&divisions=${this.pageloadData.recruiters.join(',')}`;

      this.campaignUnpostedJobsUrl = `${process.env.VUE_APP_PGM_API}/api/client/campaigns/${this.campaignId}/jobs/unposted?divisions=${this.pageloadData.recruiters.join(',')}`;
    },
    generateDateRangeJobsUrl() {
      this.dateRangeJobsUrl = `${process.env.VUE_APP_PGM_API}/api/campaign/getselecteddaterangedata?campaign_id=${this.campaignId}&start_date=${this.startDate}&end_date=${this.endDate}`;
    },
    generateCampaignChartUrl() {
      this.campaignChartUrl = `${process.env.VUE_APP_PGM_API}/api/campaign/cycleoverview/${this.cycleId}`;
    },
    generateDateRangeChartUrl() {
      this.campaignChartUrl = `${process.env.VUE_APP_PGM_API}/api/campaign/getselecteddaterangeoverview?campaign_id=${this.campaignId}&start_date=${this.startDate}&end_date=${this.endDate}`;
    },
    async changeSelectedCampaign(selected) {
      this.resetCampaignData();
      this.campaignInventory = null;
      this.campaignId = selected;
      await this.getCampaignCycles();
      this.cycleId = this.currentCycle.campaign_cycle_id;
    },
    async changeSelectedCycle(selected) {
      this.resetCampaignData();
      await this.getCampaignCycles(selected);
      this.cycleId = selected;
    },
    async selectDateRange({newStartDate, newEndDate}) {
      this.resetCampaignData();
      this.isCampaignDateRange = true;
      this.startDate = newStartDate;
      this.endDate = newEndDate;
      await this.getCampaignCycles();
    },
    displayAnalyticsJobs(data) {
      const activeTitle = data.activeJobs > 1 ? 'Active Jobs' : 'Active Job';
      const pausedTitle = data.pausedJobs > 1 ? 'Paused Jobs' : 'Paused Job';
      const activeFormat = numeral(data.activeJobs).format('0,0');
      const pausedFormat = numeral(data.pausedJobs).format('0,0');

      this.analyticsTopData[0].value = numeral(data.totalJobs).format('0,0');

      if (data.activeJobs > 0 && data.pausedJobs > 0) {
        this.analyticsTopData[0].value2 = `${activeFormat} Active | ${pausedFormat} Paused`;
      } else if (data.activeJobs > 0) {
        this.analyticsTopData[0].value2 = `${activeFormat} ${activeTitle}`;
      } else if (data.pausedJobs > 0) {
        this.analyticsTopData[0].value2 = `${pausedFormat} ${pausedTitle}`;
      }
    },
    displaySparklineGraph(data) {
      this.analyticsBtmData[0].graphData = data.clicks;
      this.analyticsBtmData[1].graphData = data.apps;
      this.analyticsBtmData[3].graphData = data.conversion.map(data => Number(data.toFixed(2)));
    },
    displayDateRangeAnalytics(analyticsData) {
      // Spend here already takes into account dividing by the ratio
      this.analyticsTopData[0].value = numeral(analyticsData.totalJobs).format('0,0');
      this.analyticsTopData[1].value = '—';
      this.analyticsTopData[2].name = 'Spend*';
      this.analyticsTopData[2].value = numeral(analyticsData.spend).format('$0,0.00');
      this.analyticsTopData[3].value = '—';
      this.analyticsTopData[4].value = numeral(analyticsData.spend / this.totalDaysBetween2Dates()).format('$0,0.00');

      this.analyticsBtmData[0].value = numeral(analyticsData.clicks).format('0,0');
      //this.analyticsBtmData[1].value = numeral(analyticsData.spend / analyticsData.clicks).format('$0,0.00');

      if (analyticsData.apps > 0) {
        this.totalApps = analyticsData.apps;
        this.makeTileClickable();
        this.analyticsBtmData[1].value = numeral(analyticsData.apps).format('0,0');
        this.analyticsBtmData[2].value = numeral(analyticsData.spend / analyticsData.apps).format('$0,0.00');
      }

      if (analyticsData.clicks > 0) {
        this.analyticsBtmData[3].value = numeral(analyticsData.apps / analyticsData.clicks).format('0,0.00%');
      }

      const jobAnalyticsData = {};

      jobAnalyticsData.totalJobs = analyticsData.totalJobs;
      jobAnalyticsData.activeJobs = analyticsData.activeJobs;
      jobAnalyticsData.pausedJobs = analyticsData.pausedJobs;

      this.displayAnalyticsJobs(jobAnalyticsData);
    },
    treatAsUTC(date) {
        var result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    },
    totalDaysBetween2Dates() {
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const end = new Date(this.endDate);

      // add 1 more day to the end date
      end.setDate(end.getDate() + 1);

      return (this.treatAsUTC(end) - this.treatAsUTC(this.startDate)) / millisecondsPerDay;
    },
    makeTileClickable() {
      // If not iframe
      if (this.isCompanyEAEnabled && this.totalApps > 0) {
        this.analyticsBtmData[1].className = this.analyticsBtmData[1].className + ' clickable-tile';
        this.analyticsBtmData[1].isClickableTile = true;
      }

      // Always
      if (this.renderUpdateBudget) {
        this.analyticsTopData[1].className = this.analyticsTopData[1].className + ' clickable-tile';
        this.analyticsTopData[1].isClickableTile = true;
      }
    },
    displayUpdateBudgetSuccessToaster(value) {
      this.updatedBudgetData = value;
      this.getCampaignCycles();
    },
    resetCampaignData() {
      this.renderData = false;
      this.cycleId = null;
      this.cycles = null;
      this.cycle = null;
      this.currentCycle = null;
      this.campaignChartUrl = null;
      this.campaignJobsUrl = null;
      this.campaignUnpostedJobsUrl = null;
      this.dateRangeJobsUrl = null;
      this.startDate = null;
      this.endDate = null;
      this.isCampaignDateRange = false;
      this.renderUpdateBudget = false;
      this.updatedBudgetData = null;
      this.analyticsTopData = [
        {
          name: 'Jobs Advertised',
          value: '—',
          className: 'col analytics-card card-top-left-border card-top-left-radius',
          isClickableTile: false,
        },
        {
          name: 'Budget',
          value: '—',
          className: 'col analytics-card card-top-left-border budget-tile',
          isClickableTile: false,
        },
        {
          name: 'Current Spend*',
          value: '—',
          className: 'col analytics-card card-top-left-border',
          isClickableTile: false,
        },
        {
          name: 'Remaining Budget*',
          value: '—',
          className: 'col analytics-card card-top-left-border',
          isClickableTile: false,
        },
        {
          name: 'Average Daily Spend',
          value: '—',
          className: 'col analytics-card card-no-bottom-border card-top-right-radius',
          isClickableTile: false,
        }
      ];
      this.analyticsBtmData = [
        {
          name: 'Clicks*',
          value: '—',
          className: 'col analytics-card card-no-right-border card-bottom-left-radius',
          isClickableTile: false,
        },
        // {
        //   name: 'CPC',
        //   value: '—',
        //   className: 'col analytics-card card-no-right-border',
        //   isClickableTile: false,
        // },
        {
          name: 'Apps',
          value: '—',
          className: 'col analytics-card card-no-right-border apps-tile-all',
          isClickableTile: false,
        },
        {
          name: 'CPA*',
          value: '—',
          className: 'col analytics-card card-no-right-border',
          isClickableTile: false,
        },
        {
          name: 'Conversion Rate*',
          value: '—',
          className: 'col analytics-card card-all-border card-bottom-right-radius',
          isClickableTile: false,
        }
      ];
    }
  }
};
</script>

<style scoped>
</style>
